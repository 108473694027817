.footerMainContainer {
    padding: 30px 150px 20px;
    display: flex;
    justify-content: space-between;
    color: white;
    text-align: left;
}

.FooterlogoTitle{
    font-size: 11px;
    font-weight: bold;
    color: white;
}

.FooterlogoDesc{
    font-size: 8px;
    color: #f2f2f2;
}

.whiteLogo {
    height: 65px;
    width: 65px;
    object-fit: contain;
}
.footerLogoContainer{
    justify-content: left;
    margin-bottom: 10px;
}
.footerHeader {
    font-weight: bold;
    margin-bottom: 15px;
    color: white;
}

.footerContent {
    padding: 5px 0;
    font-size: 15px;
    text-align: left;
    color: white;
}

.quickLink {
    text-decoration: none;
    color: #f2f2f2;
    font-size: 15px;
}

.quickLink:hover{
    color: #fff;
    font-weight: bold;
}

.quickAction {
    list-style: none;
    padding: 5px 0;
    text-align: left;
}

.contentContainer {
    display: flex;
    align-items: center;
}

.addressContainer {
    display: flex;
    align-items: flex-start;
}

.footerDesc {
    font-weight: bold;
    width: 200px;
    color: white;
}

.footerLine {
    border: 0.1px solid #7775f0ba;
    margin: 5px 50px 0;
}

.copyright {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px;
    color: #fff;
}

.footer {
    background: linear-gradient(to top, #7775f0ba, #0f0d72ca), url(../res/Images/footerWallpaper.webp) no-repeat center center;
    background-size: cover;
    opacity: 20px;
}

@media screen and (max-width:780px) {
    .footerMainContainer {
        display: block;
        padding: 20px 0;
        align-items: center;
        justify-content: center;
        text-align: center;

    }

    .footerHeader {
        margin-top: 15px;
    }

    .footerDesc {
        text-align: center;
        width: 100%;
    }

    .whiteLogo {
        text-align: center;
    }

    .contentContainer {
        justify-content: center;
    }

    .addressContainer {
        justify-content: center;
    }

    .copyrightText {
        font-size: 12px;
    }
}