.benefitIcon {
    width: 400px;
}

.benefitContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 150px;
}

.benefitSubtitle {
    font-size: 15px;
    color: #455a64;
}

.reasonContainer {
    display: flex;
}

.reasonText {
    margin-left: 20px;
    color: #455a64;
    line-height: 1.5rem;
}

.countrySubTitle {
    font-size: 17px;
    line-height: 1.7em;
    margin-top: 10px;
    color: #455a64;
    font-weight: bold;
}

.assessmentContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 200px;
    background-image: linear-gradient(to top, #455a64ba, #455a64ca);
}

.assessmentText {
    color: white;
    font-weight: bold;
    font-size: 23px;
}



.countryContainer {
    position: relative;
    box-sizing: border-box;
    width: 330px;
    margin: 30px 20px;
}

.countryFlag {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
    position: absolute;
    top: 190px;
    right: 50px
}

.countries {
    width: 330px;
    height: 220px;
    border-radius: 5px;
}

.countryName {
    color: #000;
    font-weight: bold;
    font-size: 17px;
    margin: 13px 0;
}

.countryDesc {
    color: #455a64;
    font-size: 15px;
}

.readmoreLink {
    color: #ff735c;
    font-weight: bold;
}

.universityContainer {
    background-color: #f2f2f2;
    padding: 30px 0 0;
}

.universityLogoContainer {
    display: grid;
    grid-template-columns: 14.5% 14.5% 14.5% 14.5% 14.5%;
    grid-template-rows: 45% 45%;
    grid-gap: 0.2%;
    padding: 2%;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.collegeLogo {
    height: 50px;
    width: 150px;
    align-self: 'center';
}

.collegeCard {
    padding: 14px 20px;
    margin: 15px 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: white;
}

.assessmentBtn {
    border: 2px solid white;
    font-weight: bold;
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 20px;
    text-decoration: none;
}

.assessmentBtn:hover {
    background-color: white;
    color: #455a64;
}

.rowColumnCountry {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:780px) {
    .rowColumnCountry {
        display: block;
        margin: 0 20px;
    }

    .reasonText {
        font-size: 13px;
    }

    .benefitSubtitle {
        font-size: 12.5px;
    }

    .benefitContainer {
        display: block;
        padding: 20px 20px;
        text-align: center;
    }

    .countryContainer {
        width: 100%;
        margin: 30px 0;
    }

    .countries {
        width: 100%;
        height: 170px;
    }

    .countryFlag {
        height: 3rem;
        width: 3rem;
        top: 145px;
        right: 30px;
    }

    .countryName {
        font-size: 15px;
    }

    .countryDesc {
        font-size: 13px;
    }

    .universityLogoContainer {
        grid-template-columns: 53% 53% 53% 53% 53%;
        /* grid-template-rows: 40% 40%;  */
        overflow-x: auto;
        justify-content: start;
        align-items: center;
    }

    .collegeLogo {
        height: 100%;
        width: 100%;
    }

    .collegeCard {
        height: 80%;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
        padding: 15px 15px;
        margin: 10px;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        background-color: white;
    }

    .countrySubTitle {
        font-size: 14px;
    }

    .assessmentContainer {
        display: block;
        padding: 20px 10px 30px;
        text-align: center;
    }

    .assessmentText {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .assessmentBtn {
        padding: 3px 10px;
        font-size: 18px;
    }
}