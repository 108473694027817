.legalBanner{
    background: linear-gradient(to top, #7775f0ba, #0f0d72ca), url(../res/Images/legalBanner.jpg) no-repeat center center;
    background-size: cover;
    margin: 0 auto;
    width: 100%;
    padding: 50px 250px;
    align-items: center;
    justify-content: space-between;
    background-color:  #f2f2f2;
    display: flex;
}

.legalBannerImage{
width: 450px;
}

.legalBannerText{
    font-weight: bold;
    color: white;
    font-size: 60px;
}

.legalHeader{
    font-weight: bold;
    font-size: 22px;
    text-align: left;
}

.legalDesc{
    text-align: left;
    line-height: 1.5rem;
    margin: 15px 0 15px;
}

.legalSection{
    margin: 50px 200px;
}

@media screen and (max-width:780px) {
    .legalBanner{
        display: block;
        padding: 20px 0;
        text-align: center;
          }
    .legalDesc{
        font-size: 12px;
    }
    .legalHeader{
        font-size: 17px;
    }

    .legalBannerText{
        font-size: 20px;
        text-align: center;
    }
    
    .legalSection{
        margin: 20px 20px;
    }

    .legalBannerImage{
        width: 250px;
        margin: 20px 0 10px;
    }
   
}