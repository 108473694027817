.headerInfoContainer {
    background-color: #0f0d72;
    display: flex;
    padding: 5px 150px;
    align-items: center;
    justify-content: space-between;
    color: #f2f2f2;
    font-size: 12px;
}

.headerSocialIcon {
    color: #f2f2f2;
    margin: 0 3px;
}

.bannerBackground {
    background: linear-gradient(to top, #7775f0ba, #0f0d72ca), url(../res/Images/wallpaper.png) no-repeat center center;
    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover; */
    background-size: cover;
    opacity: 20px;
    padding: 10px 0;
}

.bannerImage {
    width: 450px;
}

.logoContainer {
    text-align: center;
    margin-top: 5px;
}

.logo {
    width: 60px;
    object-fit: contain;

}

.logoTitle {
    font-size: 11px;
    font-weight: bold;
    color: white;
}

.logoDesc {
    font-size: 8px;
    color: #f2f2f2;
}

.bannerSection {
    padding: 10px 200px;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 3% 10%;
}

.nav-menu {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    width: 50%;
}

.nav-link {
    text-decoration: none;
    color: #d9d9d9;
    font-weight: 600;
    font-size: 18px;
    padding: 5px 15px;
    margin: 0 10px;
    cursor: pointer;
}

.nav-link:hover {
    color: #fff;
}

.nav-item .active {
    color: #fff;
}

.vl {
    border-left: 1px solid white;
    height: 13px;
    margin: 0 15px;
}

.mobile-menu-icon {
    display: none;
}

.footerContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 150px;
}

@media screen and (max-width:780px) {

    .nav-links {
        color: white;
        text-align: center;
        padding: 32px;
        width: 100%;
        transition: all 0.5 ease;
    }

    .mobile-nav-menu {
        position: absolute;
        display: block;
        list-style: none;
        background-color:  #2b419b;
        /* #0f0d72ca; */
        left: 0;
        top: 65px;
        padding: 50px 0 0;
        text-align: center;
        transition: all 0.5s ease-out;
        width: 100%;
        height: 100vh;
    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        font-size: 30px;
        color: #0f0d72;
        border: none;
        outline: none;
        top: 15px;
        right: 25px;
    }

    .navbar {
        position: relative;
    }

    .nav-menu {
        display: none;
    }

    .nav-item {
        padding: 30px 0;
    }

    .headerInfoContainer {
        display: none;
    }

    .logo {
        width: 40px;
    }

    .logoTitle {
        font-size: 8px;
    }

    .logoDesc{
        font-size: 6px;
    }

    .bannerSection {
        display: block;
        padding: 30px 10px 0px;
        justify-content: center;
       text-align: center;
    }

    .bannerImage {
        width: 250px;
        margin: 20px 0 10px;
    }
}