* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.bannerText {
    font-size: 38px;
    color: #f2f2f2;
    font-weight: bold;
}

.bannerSecondLineText {
    font-size: 38px;
    color: #f2f2f2;
    font-weight: bold;
    margin-bottom: 30px;
}

.headerText {
    font-size: 25px;
    color: #0f0d72;
    text-align: center;
    font-weight: bold;
}

.headerDescText {
    text-align: center;
    font-size: 17px;
    color: #455a64;
    margin: 5px 0;
}

.cardHeaderText {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}

.cardContentText {
    font-size: 15px;
    color: #455a64;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowColumn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rowLeft {
    display: flex;
    justify-content: left;
    align-items: center;
}

.padding {
    padding: 20px 200px;
}

.section {
    padding: 50px 150px;
}

.descLogo {
    width: 400px;
    margin-left: 8%;
}

.descContentContainer {
    text-align: left;
    width: 40%;
   
}

.descContentTitle {
    font-weight: bold;
    font-size: 30px;
    color: #0f0d72;
}

.descContent {
    margin: 20px 0 30px;
    line-height: 1.7em;
    color: #455a64;
    font-size: 17px;
}

.blueBgBtn {
    background-color: #0f0d72;
    color: white;
    padding: 10px 20px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.blueBgBtn:hover {
    background-color: #ff725e;
}

.paddingblueBgBtn {
    background-color: #0f0d72;
    color: white;
    padding: 10px 20px;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.paddingblueBgBtn:hover {
    color: white;
    background-color: #ff725e;
}

.blueBorderBtn {
    border: 2px solid white;
    font-weight: bold;
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
}

.blueBorderBtn:hover {
    color: #ff725e;
    border: 2px solid #ff725e;
}


.serviceContainer {
    background-color: #f2f2f2;
    padding: 30px 200px;
    align-items: center;
    justify-content: center;
}

.service {
    display: grid;
    grid-template-columns: 33% 33%;
    grid-template-rows: 50% 50%;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.service .card {
    position: relative;
    width: 349px;
    margin: 10px 10px;
    background: white;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
    overflow: hidden;
    text-align: center;
    border-radius: 5px;
}

.service .card .layer {
    position: absolute;
    top: calc(100% - 5px);
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#0f0d72ca, #7775f0ba);
    z-index: 1;
    transition: 0.5s;
}

.service .card:hover .layer {
    top: 0
}

.service .card .serviceContent {
    position: relative;
    z-index: 2;
}

.service .card:hover .cardContentText {
    color: #fff;
}

.service .card:hover .cardHeaderText {
    color: #fff;
}

.serviceIcon {
    width: 100px;
    object-fit: contain;
    align-self: center;
}

.errorContainer{
    height: 100vh;
    background-color: #fff;
    width: 100%;
    justify-content: center;
    align-items: center;
   display: flex;
}

.errorImage{
width: 30rem;
}


@media screen and (max-width:780px) {
.errorImage{
    width: 18rem;
}
    .section {
        padding: 20px 20px;
    }

    .centered {
        text-align: center;

    }

    .padding {
        padding: 20px 12px;
    }

    .rowColumn {
        display: block;
    }

    .descContentContainer {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
    }

    .descLogo {
        width: 270px;
        text-align: center;
        align-self: center;
        justify-content: center;
        justify-self: center;
        justify-items: center;
        align-items: center;
        margin-left: 0;
    }

    .bannerText {
        font-size: 20px;
        text-align: center;
    }

    .bannerSecondLineText {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
    }

    .paddingblueBgBtn {
        font-size: 13px;
        padding: 10px 50px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .blueBgBtn {
        font-size: 13px;
        padding: 9px 20px;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .blueBorderBtn {
        font-size: 13px;
        padding: 7px 10px;
    }

    .headerText {
        font-size: 18px;
    }

    .headerDescText {
        font-size: 12.5px;
    }

    .descContentTitle {
        font-size: 18px;
    }

    .descContent {
        font-size: 13px;
        margin-top: 10px;
    }

    .cardHeaderText {
        font-size: 15px;
    }

    .serviceContainer {
        padding: 10px 10px 0;
    }

    .service {
        display: block;
        align-items: center;
        justify-content: center;
        margin: 0 30px;
    }

    .service .card {
        width: 100%;
        margin: 0 0 20px !important;
        align-self: center;
        justify-content: center;
    }

    .service .card:hover .cardContentText {
        font-size: 13px;
    }

    .cardContentText {
        font-size: 13px;
    }


}