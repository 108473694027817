.bannerContainer {
    /* background-image: linear-gradient(to top, #15396d, #002d68); */
    
    background: linear-gradient(270deg,  #285f98ba, #4d4d4dba), url("https://img.freepik.com/free-photo/girl-boy-doing-exam_1098-219.jpg?size=626&ext=jpg") no-repeat center center;
    height: 550px;
    background-size: cover;
    padding: 10px 250px;

}

.center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.reach {
    color: white;
    font-size: 15px;
    margin-bottom: 8px;
    text-align: end;
}

.ielts{
     /* background: linear-gradient( #285f98,#2ba0d3, #285f98);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text; */
        font-weight: bolder;
        font-size: larger;
        color: #2ba0d3;
}

.formBg {
    background-color: #f9f9f9ba;
    width: 37%;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.bannerTitle {
    color: white
}

.bannerDesc{
    color: white;
    font-size: 14;
    margin-top: 5px;
    margin-bottom: 50px;
}

.ieltsinput {
   border-top: 0;
   border-left: 0;
   border-right: 0;
   background: transparent;
    border-bottom: 2px solid #1168d2;
/*     
    align-self: center; */

}

.ieltsinput:focus{
    border-top: 0;
   border-left: 0;
   border-right: 0;
   background: transparent;
   border-bottom: 2px solid red;
}

.ieltsSubmit {
    text-align: center;
    background-image: linear-gradient(90deg, #2ba0d3, #285f98);
    color: white;
    font-weight: 600;
    border-radius: 7px;
    /* border: 2px solid #3092d5; */
}

.orHr {
    border: 1px solid #f2f2f2;
    width: 100%;
    background-color: #f2f2f2;
    margin: 4px 10px 0px;
}

.joinUs {
    /* border: 2px solid white; */
    border-radius: 7px;
    text-decoration: none;
    color: white;
    font-weight: 600;
    padding: 10px 40px;   
    /* background-color: #2ba0d3; */
    background-image: linear-gradient(90deg, #2ba0d3, #285f98);
}

.joinUs:hover{
    background-color: #002d68;    
}

.ieltsfooter{
    background-image: linear-gradient(to right, #15396d, #002d68);
}

.section{
    padding: 50px 0;
}

.ContentTitle{
    text-align: center;
    font-weight: bolder;
}

.ContentDesc{
    color: #000000ba;
    text-align: center;
    line-height: 1.3rem;
}

.ieltsCollegesContainer{
    margin: 50px 0;
    text-align: center;
}

.ieltsColleges{
    width: 150px;
    height: 70px;
    object-fit: contain;
    margin: 0 23px;
}

.ieltsServicesDiv{
    background-color: #eaf1fb;
    padding: 50px 150px 150px;
    align-items: center;
    justify-content: center;
}

.ieltsOffer{
    background-color: #f9f9f9;
    align-self: center;
    justify-content: center;
    padding: 50px;
}

.ieltsCard{    
        margin: 30px 10px 0;
        position: relative;
        width: 349px;
        background: white;
        padding: 20px;
        box-sizing: border-box;
        box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
        overflow: hidden;
        text-align: center;
        border-radius: 5px; 
}

.ieltsCardTitle{
    color: #2ba0d3;
    margin: 10px 0;
}

.ieltsCardContent{
    font-size: small;
    color: gray;
    line-height: 1.1rem;
}

.ieltsApplyBtn{
    background-image: linear-gradient(90deg, #2ba0d3, #285f98);
    color: white;
    font-weight: 600;
    padding: 10px 0px;
    margin: 20px;
    width: 150px;
   text-align: center;
  
   border-radius: 20px;
   cursor: pointer;
   
}

.rowStart{
    display: flex;
    align-items: flex-start;

}

.serviceGrid{
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 68% 68% 68%;
    grid-gap: 1.5%;
    padding: 2%;
    justify-content: center;
    align-items: center;
}
.ieltscount{
color: #1168d2;
font-weight: 600;
font-size: 13px;
}
.serviceHr{
    border: 1px solid black;
    width: 30px;
    background-color: black;
    margin: 6.5px 10px 0px;
}

.ieltsContactContainer{
    /* display: flex;
    margin: 20px 210px;
    padding: 40px 50px;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(90deg, #2ba0d3, #285f98);
    border-radius: 10px; */
    display: flex;
    padding: 50px 250px;
    justify-content: center;
}

.blueHeader{
    color: #285f98;
    font-weight: 600;
    text-align: center;
    margin-bottom: 7px;
}

.ieltsIcon{
    object-fit: contain;
    height: 70px;
    width: 70px;
}

.serviceIcon{
    object-fit: contain;
    height: 100px;
    width: 100px;
}