.featureContainer {
    background-color: #f2f2f2;
    padding: 50px 200px;
}

.feature {
    display: flex;
    /* align-items: center; */
    justify-content: center;
}

.feature .card {
    margin: 30px 10px 0;
    position: relative;
    width: 349px;
    background: white;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, .2);
    overflow: hidden;
    text-align: center;
    border-radius: 5px;

}

.feature .card .layer {
    position: absolute;
    top: calc(100% - 5px);
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#0f0d72ca, #7775f0ba);
    z-index: 1;
    transition: 0.5s;
}

.feature .card:hover .layer {
    top: 0
}

.feature .card .featureContent {
    position: relative;
    z-index: 2;

}

.feature .card:hover .cardContentText {
    color: #fff;
}

.feature .card:hover .cardHeaderText {
    color: #fff;    
}

.featureIcon {
    width: 100px;
    object-fit: contain;
    align-self: center;
}

.benefitCountryFlag {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.benefitCard {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 20px;
    margin: 30px 10px 10px 10px;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    display: flex;
    background-color: white;
}

.benefitCountryTextContainer {
    margin-left: 30px;
}

.countryBenefitContainer {
    background-color: #f2f2f2;
    padding: 30px 0;
}

.meetText {
    font-size: 17px;
    color: #455a64;
    line-height: 1.7em;
}

.personalizeContainer {
    margin-left: 50px;
}

.personalGuidanceIcon {
    width: 120px;
}

.personalizeText {
    font-size: 23px;
    font-weight: bold;
    color: black;
    margin-bottom: 30px;
}

.journeyContainer {
    margin: 50px 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.journeySection {
    text-align: center;
    align-items: center;
    justify-content: center;
    /* margin: 0 20px ; */
}

.journeyIcon {
    width: 120px;
    margin: 5px 20px;
}

.arrow {
    width: 20px;
    margin-bottom: 40px;
    object-fit: contain;
}

.journeyName {
    font-size: 15px;
    color: black;
    font-weight: bold;
    margin: 15px 0 10px;
}

.journeyStepText {
    font-size: 13px;
    color: #455a64;
}

.newsContainer {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 20px;
}

.newsCard {
    width: 310px;
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    margin: 30px 10px 10px 10px;
    text-align: center;
    border-radius: 7px;
    background-color: white;
}

.newsIcon {
    width: 150px;
}

.newsTitle {
    background-color: #0f0d72;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 10px;
    color: white;
    font-weight: bold;
}

.newsHeading {
    color: black;
    padding: 15px 15px 5px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
}

.newsDesc {
    color: #455a64;
    padding: 0px 15px 15px;
    text-align: left;
    font-size: 15px;
}

.guidanceContainer {
    display: flex;
    justify-content: center;
}

.homeDescLogo {
    width: 400px;
    margin-left: 8%;
}

.JourneyMainContainer {
    background-color: #f2f2f2;
    padding: 30px 200px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width:780px) {
    .JourneyMainContainer {
        padding: 15px 10px;
    }

    .feature {
        display: block;
        align-items: center !important;
        justify-content: center;
        margin-top: 30px;
    }

    .featureContainer {
        padding: 20px 30px;
    }

    .feature .card {
        width: 100%;
        margin: 0 0 20px;
        align-self: center;
        justify-content: center;
    }

    .featureIcon {
        width: 80px;
    }

    .benefitCard {
        padding: 13px 40px;
        margin: 15px 30px;
        justify-content: space-between;
    }

    .benefitCountryFlag {
        width: 70px;
        height: 70px;
    }

    .guidanceContainer {
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .personalizeContainer {
        margin-left: 0;
    }

    .personalGuidanceIcon {
        width: 80px;
    }

    .personalizeText {
        font-size: 15px;
    }

    .journeyContainer {
        justify-content: flex-start;
        display: flex;
        margin: 30px 0 0;
        overflow-x: auto;
    }

    .journeyContainer ::-webkit-scrollbar {
        width: 0;
    }

    .newsContainer {
        display: block;
        align-items: center;
        justify-content: center;
        margin: 20px 20px;
    }

    .newsCard {
        width: 100%;
        margin: 15px 0;
        align-self: center;
    }

    .newsTitle {
        font-size: 14px;
        padding: 7px 7px;
    }

    .newsHeading {
        font-size: 13px;
    }

    .newsDesc {
        font-size: 13px;
    }

    .journeyIcon {
        width: 100px;
    }

    .homeDescLogo {
        width: 270px;
        margin-left: 0;
        margin-top: 20px;
    }
}