.serviceStepCount {
    border-radius: 50%;
    border-width: 1;
    color: white;
    padding: 2.3% 4%;
    font-size: small;
    font-weight: bold;
    border: 2px solid white;
}

.serviceStepContainer {
    background-image: linear-gradient(to left, #7775f0ba, #0f0d72ca);
    padding: 20px 15px;
    margin-left: 80px;
    width: 32%;
    border-radius: 5px;
}

.serviceStepText {
    color: white;
    margin-left: 20px;
    font-weight: bold;
    width: 100%;
}

.serviceStep {
    display: flex;
    justify-content: left;
    padding: 10px 10px;
    align-items: center;
}

.perkContainer {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-gap: 0.5%;
    padding: 2%;
    align-items: center;
    justify-content: center;
}

.perkCard {
    display: flex;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    padding: 20px 20px;
    margin: 5px 5px;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    display: flex;
    background-color: white;
}

.perkIcon {
    height: 50px;
    width: 50px;
}

.perkText {
    margin-left: 15px;
    font-size: 15px;
    color: black;
    font-weight: bold;
    text-align: center;
}

.serviceItemsContainer {
    display: grid;
    grid-template-columns: 25% 25% 25%;
    /* grid-template-rows: 33% 33% 33%; */
    grid-gap: 0.5%;
    padding: 2%;
    justify-content: center;
}

.serviceItemCard {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
    padding: 20px 20px;
    margin: 10px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: white;
}

.serviceItemIcon {
    object-fit: contain;
    width: 70px;
}

.serviceItemHeader {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0;
}

.serviceItemDesc {
    font-size: 15px;
    color: #455a64;
}

.foundContainer {
    text-align: center;
    padding: 30px 0;
    background-image: linear-gradient(to top, #455a64ba, #455a64ca);
}

.foundTitle {
    color: white;
    font-size: 20px;
    font-weight: bold;
    /* text-align: center; */
}

.foundDesc {
    color: white;
    font-size: 15px;
    /* text-align: center; */
    margin: 7px 0 40px;
}

.foundBtn {
    border: 2px solid white;
    font-weight: bold;
    color: white;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
}

.foundBtn:hover {
    background-color: #fff;
    color: #455a64;


}

@media screen and (max-width:780px) {
    .serviceItemsContainer {
        display: block;
    }

    .perkContainer {
        overflow-x: auto;
        grid-template-columns: 80% 80% 80% 80%;
        grid-template-rows: 45% 45%;
        padding: 2%;
        justify-content: flex-start;
        align-items: center;
        margin-top: 15px;
    }

    .perkCard {
        padding: 10px 10px;
        margin: 10px 5px;
    }

    .foundTitle {
        font-size: 15px;
        padding: 0 10px;
    }

    .foundDesc {
        font-size: 13px;
        padding: 0 5px;
        margin: 5px 0 25px;
    }

    .foundBtn {
        padding: 5px 10px;
        font-size: 13px;
    }

    .serviceStepContainer {
        margin-left: 15px;
        margin-right: 15px;
        width: 90%;
    }

    .serviceItemHeader {
        font-size: 15px;
    }

    .serviceItemDesc {
        font-size: 13px;
    }
}