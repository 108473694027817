.contactSeparator {
    border-left: 2px solid #0f0d72ca;
    height: 140px;
    margin: 0 50px;
}

.socialmediaBox {
    background-color: #0f0d72;
    padding: 7px;
    border-radius: 5px;
    margin: 5px;
}

.socialmediaBox:hover{
background-color: #ff725e;
}

.contactDetails {
    text-align: center;
}

.contactHeader {
    color: #0f0d72;
    font-weight: bold;
    margin: 10px 0;

}

.contactInfo {
    margin: 5px 5px;
    color: #455a64;
}

.submitBtn {
    width: 200px;
    padding: 10px 10px;
    border-color: #0f0d72;
    border-radius: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    background-color: #0f0d72;
}

.submitBtn:hover{
    background-color: #ff725e;
    border-color: #ff725e;
}

.formTitle {
    font-weight: bold;
    color: black;
    text-align: center;
    margin: 0 0 5px;
}

.formContainer {
    padding: 50px 200px 10px;
    text-align: center;
}

.fieldLabel {
    text-align: left;
    color: #0f0d72ca;
    margin-left: 25%;
    font-weight: bold;
}

.fieldInput {
    width: 50%;
}

.fieldInput:focus {
    border: 2px solid #0f0d72ca;
    outline: none !important;
}

input, textarea {
    width: 90%;
    padding: 12px 20px;
    margin: 8px 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: left;
}

.error {
    color: red;
    font-size: 12px;
    text-align: center;
}

.socialContainer {
    text-align: center;
    margin-bottom: 30px;
}

.contactHeaderDesc {
    text-align: center;
    font-size: 17px;
    color: #455a64;
    margin: 5px 0 60px;
}

@media screen and (max-width:780px) {
    .contactSeparator {
        display: none;
    }

    .contactHeaderDesc{
        font-size: 12.5px;
        margin: 5px 0 0;
    }

    .formContainer {
        padding: 30px 0 0 !important;
    }

    .contactHeader {
        font-size: 15px;
    }

    .contactInfo {
        font-size: 13px;
    }

    .contactDetails {
        margin-top: 25px;
    }

    .formTitle {
        font-size: 14px;
    }

    .fieldLabel {
        font-size: 13px;
        margin-left: 15px;
    }

    .fieldInput {
        width: 95%;
    }

    .socialmediaBox{
        padding: 4px;
    }
}