.valueIcon{
    width: 70px;
}

.valuesContainer{
    display: flex;
    position: relative;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.valuesCard{
    border-radius: 5px 30px 5px 5px;
    height: 25rem;
    margin: 2% 2%;
    padding: 2% 2%;
    box-shadow: 0 4px 8px 0 #00000033;
    border-right: 6px solid #0f0d72ca;
    align-items: center;
    justify-content: center;
}

.valuesContent{
    color: #455a64;
    font-size: 16px;
    width: 200px;
    line-height: 1.5em;
}

@media screen and (max-width:780px) {
    .valuesContainer{
        display: block;
    }

    .valuesCard{
        padding: 10px 10px;
        height: 20rem;
        margin: 20px 50px;
    }
    .valuesContent{
        width: 100%;
        padding: 10px 10px;
    }

    .mobileCenter{
        text-align: center;
        margin-top: 30px;
    }
}